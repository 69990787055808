.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.backdrop {
  position: fixed;
  inset: 0;
  height: 100vh;
  background: rgba(0,0,0,0.9);
  background-image: url('https://cdn.imaginarity.com/public/thepage/PAG_X.svg');
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 100000000;
  backdrop-filter: blur(6px);
}
.backdrop button {
  position: fixed;
  top: 50vh;
  left: 50vw;
  border-radius: 0;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  background: #f5f4f7;
  box-shadow: none;
  border: none;
  color: #403F45;
  cursor: pointer;
  font-size: 20px;
  font-weight: 900;
  outline: none;
  font-family: "Porsche Next", "Arial Narrow", Arial, "Heiti SC", sans-serif;
  transition: all 0.2s ease-in-out;
  transform: translate(-50%, -50%);
}
.backdrop  button:hover{
  background: #efeef1;
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}